import { ref, computed, onScopeDispose } from "vue";
import { useRouter } from "vue-router/composables";
import { cockpitExplorerService } from "@/api";
import { useStatusBarSelect } from "@/composition/filtering-components/use-status-bar-select"
import { BUSINESS_STATUS_LIST } from "./use-explorers-csv";
import { findAndReplace } from "@/js/helper";

export const TABLE_ACTIONS = ['explorer-tests', 'activation', 'reboot', 'assign-device-pool', 'history', 'delete'];

export const TABLE_COLUMNS = [
  { name: "", key: "actions", sortable: false, width: 78 },
  { name: "Name", key: "name", sortable: true, },
  { name: "Service Code", key: "serviceCode", sortable: true },
  { name: "MAC address", key: "systemInfo.systemType.networkInterfaces.eth0", sortable: true, sortKey: "macAddress" },
  { name: "Status", key: "status", sortable: true, },
  { name: "Description", key: "description", sortable: true, },
  { name: "Tags", key: "tags", sortable: false, },
  { name: "Device Pool", key: "devicePool.name", sortable: false },
  { name: "Test Statistics", key: "testStatistics", sortable: false, width: 400 },
];

const POSSIBLE_STATUSES = [
  {
    "id": 1, //value
    "name": "online", //key
    "text": "Online", //text
    "color": "ok",
    "order": 0
  },
  {
    "id": 2,
    "name": "warning",
    "text": "Warning",
    "color": "testStatusWarning",
    "order": 1
  },
  {
    "id": 0,
    "name": "offline",
    "text": "Offline",
    "color": "testStatusError",
    "order": 2
  }
];

const safeStringParamToInt = (p, defaultVal) => {
  if (typeof p === "string") {
    const parsed = Number(p);
    if (!Number.isNaN(parsed) && Number.isFinite(parsed) && parsed >= 0) {
      return parsed;
    } else {
      return defaultVal;
    }
  }
  return p;
};

export const useExplorersTable = ({
  params = {
    page: 1,
    itemsPerPage: 25,
    search: "",
    sortBy: "",
    descending: false,
    historyFor: 0,
    full: false,
    from: Math.trunc(new Date(0).getTime() / 1000),
    to: Math.trunc(new Date().getTime() / 1000),
    devicePool: 0,
    tags: "",
    status: -1,
    online: "",
  },
  refreshMs = 30000,
}) => {
  const {
    selectTotalStatuses,
    statusesCounts,
    statusesFilter: onlineFilter,
    statusesRepr,
    statusTotal,
    statusesAsParamString: onlineFilterAsParamString,
  } = useStatusBarSelect({
    possibleStatuses: POSSIBLE_STATUSES,
    multiple: true
  });

  if (params.online && parseInt(params.online) != -1) {
    onlineFilter.value = params.online.split(",").map(Number);
  }

  const _generateCacheKey = (_p) => JSON.stringify(_p);
  const _cache = new Map;
  // eslint-disable-next-line no-undef
  const _params = ref(structuredClone(params));
  _params.value.devicePool = safeStringParamToInt(params.devicePool, 0);
  _params.value.status = safeStringParamToInt(params.status, -1);

  const router = useRouter();
  const explorersList = ref([]);
  const isLoading = ref(false);
  const meta = ref({
    page: 1,
    pageCount: 1,
    itemsPerPage: 25,
    totalCount: 0,
  });
  const businessStatusTypes = ref([]);
  const devicePools = ref([]);

  const businessStatusList = computed(() => {
    return [
      BUSINESS_STATUS_LIST[0],
      ...BUSINESS_STATUS_LIST.filter(status => 
        businessStatusTypes.value.includes(status.value)
      )
    ];
  });

  const updateMeta = (newMeta) => {
    meta.value = newMeta
  };

  const retrieveMeta = (resp) => {
    const { page, pageCount, perPage: itemsPerPage, totalCount } = resp._metadata;

    return {
      page,
      pageCount,
      itemsPerPage,
      totalCount,
    }
  };

  const updateExplorersTable = async (__params, { isSilent = false, ignoreCache = false } = {}) => {
    const cacheKey = _generateCacheKey(__params);
    const cachedData = _cache.get(cacheKey);
    const now = Date.now();

    if (cachedData && now - cachedData.timestamp < refreshMs && !ignoreCache) {
      // Serve cached data
      explorersList.value = cachedData.records
      updateMeta(retrieveMeta(cachedData.response))
    } else {
      if (!isSilent) {
        isLoading.value = true;
      }

      try {
        const response = await cockpitExplorerService.v1.getExplorersTable(__params);
        explorersList.value = response.records || [];

        if (explorersList.value.length > 0) {
          updateMeta(retrieveMeta(response))
          _cache.set(cacheKey, { records: response.records, response, timestamp: now });
        }
      } catch (err) {
        explorersList.value = [];
        // notification
        throw err;
      } finally {
        isLoading.value = false;
      }
    }
  };

  const updateExplorersCounts = async ({ status, tags, search }) => {
    statusesCounts.value = await cockpitExplorerService.v1.getExplorerCounts({ status, tags, search });
  };

  const updateRouteQuery = (newParams) => {
    router.push({
      query: {
        ...router.currentRoute.query,
        page: newParams.page,
        itemsPerPage: newParams.itemsPerPage,
        search: newParams.search,
        sortBy: newParams.sortBy,
        descending: newParams.descending,
        historyFor: newParams.historyFor,
        full: newParams.full,
        from: newParams.from,
        to: newParams.to,
        devicePool: newParams.devicePool,
        tags: newParams.tags,
        status: newParams.status,
        online: newParams.online,
      },
    });
  };

  const updateTableData = async ({ isSilent = false, ignoreCache = false } = {}) => {
    await Promise.all([
      updateExplorersTable(_params.value, { isSilent, ignoreCache }),
      updateExplorersCounts({ 
        tags: _params.value.tags, 
        status: _params.value.status, 
        search: _params.value.search 
      }),
    ]);
  };
  const updateParams = async (newParams) => {
    _params.value = newParams
    onlineFilter.value = newParams.online;
    updateRouteQuery({ ...newParams, online: onlineFilterAsParamString.value })
    await updateTableData()
  };

  const updateExplorerInTable = (explId, newExpl) => {
    explorersList.value = findAndReplace(explorersList.value, newExpl, (expl) => expl.id === explId);
  };

  const clearFileters = () => {
    updateParams({
      ..._params.value,
      online: [],
      status: -1,
      tags: "",
      devicePool: 0,
      search: "",
      page: 1,
    });
  };

  let intervalId = null;
  onScopeDispose(() => {
    clearInterval(intervalId)
  });
  const init = async () => {
    const [_businesStatusTypes, _devicePools] = await Promise.all([
      cockpitExplorerService.v1.getBusinessStatusTypes(),
      cockpitExplorerService.v1.getDevicePools(),
      updateTableData(),
    ]);
    businessStatusTypes.value = _businesStatusTypes;
    devicePools.value = _devicePools;

    // Start periodic refresh
    intervalId = setInterval(() => {
      updateTableData({ isSilent: true });
    }, refreshMs);
  };

  return {
    init,
    params: _params,
    meta,
    isLoading,
    explorersList,
    updateParams,
    statusTotal,
    onlineFilter,
    selectTotalStatuses,
    statusesCounts,
    statusesRepr,
    updateExplorersTable,
    updateTableData,
    updateExplorerInTable,
    businessStatusList,
    devicePools,
    clearFileters,
  };
};
